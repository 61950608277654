<template>
    <div v-if="canceled.includes(status)">
        <span class="status-dot canceled me-3"></span>
        {{ status }}
        <span v-if="this.date" class="float-end date">{{ this.date }}</span>
    </div>
    <div v-if="light.includes(status)">
        <span class="status-dot light me-3"></span>
        {{ status }}
        <span v-if="this.date" class="float-end date">{{ this.date }}</span>
    </div>
    <div v-if="warn.includes(status)">
        <span class="status-dot warn me-3"></span>
        {{ status }}
    </div>
    <div v-if="success.includes(status)">
        <span class="status-dot success me-3"></span>
        {{
            status +
                (externalHandler && status === "Afwerker"
                    ? ` ${externalHandler}`
                    : "")
        }}
    </div>
    <div v-if="!status">
        <span class="status-dot me-3"></span>
        Onbekend
    </div>
</template>

<script>
export default {
    name: "StatusDot",
    data() {
        return {
            canceled: ["Geannuleerd"],
            light: [
                "Order geplaatst",
                "Wachten op bestanden",
                "Productiebon geprint",
            ],
            warn: [
                "Open",
                "Geplaatst",
                "Wachten op betaling",
                "Uitbesteed",
                "Uitbesteding geannuleerd",
                "In productie",
                "In proef",
                "Geprint",
                "Afwerking voltooid",
                "Afwerking uitbesteed",
                "Verzenddatum aangepast",
                "Afwerking niet voltooid",
                "Niet geprint",
                "Bestand geupload",
                "Bestand goedgekeurd",
                "Bestand afgekeurd",
            ],
            success: [
                "Voldaan",
                "Closed",
                "Betaling ontvangen via Mollie",
                "Verzonden",
                "Klaar voor Pickup",
                "Afwerker",
            ],
        };
    },
    props: {
        status: String,
        date: String,
        externalHandler: String,
    },
    created() {
        // if (status) console.log(this.status);
    },
};
</script>

<style>
.status-dot {
    height: 15px;
    width: 15px;
    background-color: #d6d5d5;
    border-radius: 50%;
    display: inline-block;
}

.status-dot.canceled {
    background-color: red;
}

.status-dot.light {
    background-color: #fff;
}

.status-dot.warn {
    background-color: #ffc107;
}

.status-dot.success {
    background-color: #28a645;
}

.date {
    color: #707070;
}
</style>
